import { Header } from '@/components/Header';
import { QRIdDisplay } from '@/components/QRIdDisplay';

import ReportSuccessSVG from '../../assets/success_report.svg';
import { useNavigate } from 'react-router-dom';

export const ReportSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <div className="mx-auto flex w-2/5 grow justify-center">
        <img src={ReportSuccessSVG} alt="Happy face" />
      </div>
      <p className="font-bold text-accent">Din närvarostatus har rapporterats.</p>
      <button
        className="btn btn-primary text-white"
        onClick={() => navigate(-1)}
      >
        Tillbaka
      </button>
      <QRIdDisplay />
    </>
  );
};
